import React from 'react';

const Register = () => {
    return (
        <div>
            Register
        </div>
    );
}

export default Register;
