import React from 'react';

const Book = () => {
    return (
        <div>
            Book
        </div>
    );
}

export default Book;
