import React from 'react';

const SignIn = () => {
    return (
        <div>
            Signin
        </div>
    );
}

export default SignIn;
